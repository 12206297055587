<template>
  <div
    class="success-create"
    @click="emitCloseSuccess"
  >
    <popup-base @clickOnBackground="emitCloseSuccess">
      <div class="bg-white rounded p-5 w-1/2 m-auto">
        <h3 class="text-2xl font-bold p-2">
          {{ $t('saveAudience') }}
        </h3>
        <div class=" p-3 rounded text-xl">
          <h3 class=" w-full font-normal success leading-10">
            {{ $t('enterName') }}
          </h3>
          <input
            v-model="name"
            class="h-16 w-full border font-normal  leading-10 rounded p-2 text-xl mt-2"
          >
        </div>
        <div class="button-section mt-3  w-full">
          <button
            class="px-3 py-5 w-full rounded font-bold text-white text-center uppercase text-center bg-green"
            @click="success"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import { firebaseDb } from '../../../main'
import firebase from 'firebase'
import PopupBase from './popup-base'
export default {
  name: 'SuccessCreate',
  components: { PopupBase },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      name: ''
    }
  },
  methods: {
    async success () {
      if (this.name !== '') {
        this.data['name'] = this.name
        this.data['createdAt'] = Date.now()
        console.log(Date.now())
        console.log(this.data)
        const key = await firebaseDb.ref('pubAudience').child(firebase.auth().currentUser.uid).push().key
        this.data['key'] = key
        await firebaseDb.ref('pubAudience').child(firebase.auth().currentUser.uid).child(key).set(this.data)
        this.$toast.success('Audience enregistrée')
        this.$emit('emitSuccessCreateEnd', true)
      } else {
        alert('Veuillez renseigner un nom pour votre audience')
      }
    },
    emitCloseSuccess () {
      this.$emit('emitCloseSuccess', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .success {
    line-height: 2.3rem;
  }
</style>
