<template>
  <div class="insufficient-amount" @click="emitCloseInsufficient">
    <popup-base @clickOnBackground="emitCloseInsufficient">
      <div class="bg-white rounded p-5 w-1/2 m-auto ">
        <h3 class="text-2xl font-bold ">{{ $t('insufficientTitle') }}</h3>
        <div class="mt-3 p-3  rounded text-xl ">
          <h3 class=" w-full font-normal text-red">{{ $t('insufficientSubtitle') }}</h3>
        </div>
        <div class=" p-3 rounded text-xl">
          <h3 class=" w-full insufficient-text font-normal">{{ $t('insufficientText') }}</h3>
        </div>
        <div class="button-section mt-3 w-full">
          <button class="px-3 py-5 w-full rounded font-bold text-white text-center uppercase text-center bg-green"
          @click="emitRecharge">{{ $t('fundingMyWallet')}}</button>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
export default {
  name: 'InsufficientAmount',
  components: { PopupBase },
  methods: {
    emitRecharge () {
      this.$emit('wantToRecharge', true)
    },
    emitCloseInsufficient () {
      this.$emit('emitCloseInsufficient', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .insufficient-text {
    line-height: 2.2rem;
  }
</style>
