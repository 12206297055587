<template>
  <div
    class="confirm-debit"
    @click="emitCloseConfirm"
  >
    <popup-base @clickOnBackground="emitCloseConfirm">
      <div
        class="confirm-debit bg-white m-auto w-1/2 p-5 rounded"
      >
        <h3 class="text-2xl font-bold">
          {{ $t('accountNotValid') }}
        </h3>
        <div class="mt-6">
          <h3 class="tracking-widest text-2xl leading-10 confirm-text font-normal  ">
            {{ $t('accountNotValidText') }}
          </h3>
        </div>
        <div class="action-button-section justify-between  flex mt-6">
          <div
            class="flex-one w-1/2 text-center p-1 cancel-button m-1"
            @click="emitCancelCreate"
          >
            <button class="button px-2 cancel py-4 text-center font-bold uppercase">
              {{ $t('accountNotValidCancel') }}
            </button>
          </div>
          <div
            class="flex-one w-1/2 bg-green rounded p-1 text-center m-1"
            @click="emitSaveCreate"
          >
            <button class="button px-2  py-4 text-center text-white  font-bold uppercase ">
              {{ $t('accountNotValidDelete') }}
            </button>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
export default {
  name: 'AccountNotValided',
  components: { PopupBase },
  props: {
    amount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    emitCancelCreate () {
      this.$emit('emitCancelCreateNotValid', false)
    },
    emitSaveCreate () {
      this.$emit('emitSaveCreateNotValid', false)
    },
    emitCloseConfirm () {
      this.$emit('emitCloseConfirmNotValid', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
  .amount-section {
    background: $Rema-Icon-Background;
  }
  .cancel {
    color: #BCBCBC;
  }
  .confirm-text {
    line-height: 2.3rem;
  }
</style>
